<template>
  <div class="scheme">
    <img
      :src="replace_domain(detail.headIcon)"
      class="banner"
      :style="{
        '--img_width': '100%',
        '--img_height': detail.headIconHeight + 'px',
      }"
    />
    <div class="main">
      <div
        v-html="replace_domain(detail.content)"
        class="main-content"
        id="main-content"
      />
      <div class="pl" :style="{ height: height + 'px', width: '100%' }" />
      <div class="qq" v-if="$route.query.title == '信息化运维'">
        <div class="service">
          <div class="fs16">在线客服</div>
        </div>
        <div class="mTop10 link">
          <a
            href="//wpa.qq.com/msgrd?v=3&uin=1330999883&site=qq&menu=yes"
            class="hand alCen"
            target="_blank"
          >
            <img src="../../assets/image/scheme/qq.gif" class="link-img" />
            <div class="fs14 col666 maLf8">和财网络</div>
          </a>
        </div>
        <div class="alCen bold mTop8 maLf16">
          <img src="../../assets/image/scheme/time.png" class="time" />
          <div class="col666 maLf8 fs16">工作时间</div>
        </div>
        <div class="fs14 col666 mTop16 maLf16">
          周一至周五 ：8:30-21:00周六至周日 ：9:00-21:00
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "scheme",
  components: {},
  data() {
    return {
      height: 0,
      id: null,
      fontSize: 0,
      detail: {
        headIcon: "",
        headIconWidth: "100%",
        headIconHeight: "600px",
        content: "",
      },
      map: [],
      currentMobile: false,
    };
  },
  created() {
    this.getSchemeByTitle();
  },
  computed: {
    replace_domain() {
      return (v) => {
        return v.replace(/8.129.187.206/g, "www.hcwisdom888.com");
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.createScript();
      this.computedHeight();
      this.currentMobile = this.isMobile();
    });
    window.onresize = () => {
      setTimeout(() => {
        if (this.isMobile() != this.currentMobile) {
          location.reload();
        }
        this.currentMobile = this.isMobile();
      }, 0);
    };
  },
  watch: {
    $route(to, from) {
      if (to.query.title) {
        this.getSchemeByTitle();
      }
    },
  },

  methods: {
    getSchemeByTitle() {
      if (this.$route.query.title == undefined) return;
      this.request({
        url: this.$api.getSchemeByTitle,
        data: {
          str: this.$route.query.title,
        },
      }).then((res) => {
        if (res.code == 0) {
          this.detail = res.data[0];
          document.title = res.data[0].title;
          this.$nextTick(() => {
            let scale = this.windowScale();
            if (!this.isMobile()) scale = 1;
            this.computedHeight(scale);
          });
        }
      });
    },
    windowScale() {
      if (this.isMobile()) {
        let dom = document.querySelector(".main-content");
        dom.style.margin = "0 10px";

        let dom2 = document.querySelector(".main");
        let right = document.querySelector(".richContent");
        let web_dom = document.querySelector(".g_web");
        let special = document.querySelector(".abcdefg");
        if (special) {
          if (special.style.transform.indexOf("60") >= 0) {
            special.style.transform = "translateX(14px)";
          }
        }
        if (web_dom) {
          if (this.$route.query.title.indexOf("乡村振兴大数据平台") >= 0) {
            dom.style.zoom =
              (dom2.offsetWidth - 20) / web_dom.offsetWidth + 0.28;
          } else {
            dom.style.zoom =
              (dom2.offsetWidth - 20) / web_dom.offsetWidth + 0.1;
          }
          if (web_dom.style.transform.indexOf("374") >= 0) {
            web_dom.style.transform = "translateX(-174px)";
          } else if (web_dom.style.transform.indexOf("376") >= 0) {
            web_dom.style.transform = "translateX(-200px)";
          } else if (web_dom.style.transform.indexOf("347") >= 0) {
            web_dom.style.transform = "translateX(-335px)";
          }
          return dom.style.zoom;
        } else if (right) {
          dom.classList.add("div_auto");
          dom.style.zoom = 1;
        }
        let max_width = 0;
        let title = this.$route.query.title;
        if (title == "乡村振兴") {
          dom.style.margin = "0 0 0 60px";
        } else if (
          title == "室外气象站" ||
          title == "智慧畜牧养殖系统" ||
          title == "农业控制系统" ||
          title == "智慧环保" ||
          title == "乡村振兴与智慧农业物联网" ||
          title == "智慧乡村系统"
        ) {
          dom.style.margin = "0 0 0 30px";
        }
        get(dom);
        function get(parent) {
          let dom_list = Array.prototype.slice.apply(parent.children);
          dom_list.forEach((item) => {
            if (item.children.length != 0) {
              get(item);
            }
            let width = item.offsetWidth;
            if (width && width > max_width) {
              max_width = width;
            }
          });
        }
        dom.style.zoom = (dom2.offsetWidth - 20) / max_width - 0.03;
        return dom.style.zoom;
      }
    },
    computedHeight(scale) {
      this.height = 1000;
      let i = 0;
      let id = setInterval(() => {
        if (this.height == 1000) {
          this.height = 0;
        }
        i += 1;
        let parent = document.querySelector(".main-content");
        if (parent.offsetHeight < 400) {
          let list = [];
          let max = 0;
          let max_dom = null;
          let min = 0;
          get(parent);
          function get(parent) {
            let dom_list = Array.prototype.slice.apply(parent.children);
            dom_list.forEach((item) => {
              if (!item.style.top) {
                get(item);
              } else {
                list.push(item);
                let top = parseInt(item.style.top.replace("px", ""));
                if (top > max) {
                  max = top;
                  max_dom = item;
                }
                if (top < min) {
                  min = top;
                }
              }
            });
          }
          let args = 90;
          if (
            this.$route.query.title == "智慧硬件" ||
            this.$route.query.title == "乡村振兴与智慧农业物联网"
          ) {
            args = 310;
          }
          if (max_dom) {
            this.height = max - min + max_dom.offsetHeight + args;
          } else {
            this.height = max - min + args;
          }
          this.height = this.height * parseFloat(scale);
          if (this.$route.query.title == "信息化运维" && this.isMobile()) {
            this.height = 0;
          }
          if (i >= 3) {
            clearInterval(id);
          }
        }
      }, 1000);
    },

    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      )
        return true; // 移动端
      else return false; // PC端
    },
    isZoom() {
      // let dom = document.getElementById("main-content");
      // if (this.isMobile()) {
      //   dom.style.zoom = "0.75";
      //   dom.style.margin = "inherit";
      // } else {
      //   dom.style.zoom = "1";
      //   dom.style.margin = "auto";
      // }
      let clientWidth = document.documentElement.clientWidth;
      let dom = document.getElementById("main-content");
      dom.style.zoom = clientWidth / 1903;
    },
    createScript() {
      let script = document.createElement("script");
      script.src = process.env.BASE_URL + "js/common.js";
      document.getElementsByTagName("head")[0].appendChild(script);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./scheme.less");
</style>
